// (function() {
/* LIBRIARES */
var problemsSlider = new Splide('.splide-problems', {
    loop: false,
    perPage: 2,
    arrows: false,
    gap: 20,
    padding: 20,
    drag: true,
    swipeDistanceThreshold: 10,
    pagination: true,
    breakpoints: {
        2560: {
            // pagination: false,
            // drag: false,
            perPage: 5,
        },
        1070: {
            perPage: 4,
        },
        860: {
            perPage: 3,
        },
        767: {
            gap: 12,
            fixedWidth: '210px',
        },
        479: {
            focus: true,
            fixedWidth: '210px',
        }
        
    }
});


// problemsSlider.on('mounted', function() {
//     if (document.documentElement.offsetWidth > 1070 && problemsSlider.length > 5) {
//         problemsSlider.options.breakpoints[2560] = {
//             pagination: false,
//             drag: true,
//             perPage: 5,
//         }
//     }
// });
problemsSlider.on('drag', function() {
    var cards = document.querySelectorAll('.splide-problems .problems-card');
    for (var i = 0; i < cards.length; i++) {
        cards[i].classList.add('is-shadow');
    }
});
problemsSlider.on('dragged', function() {
    var cards = document.querySelectorAll('.splide-problems .problems-card');
    for (var i = 0; i < cards.length; i++) {
        removeShadow(cards[i]);
    }
    function removeShadow(elem) {
        setTimeout(function() {
            elem.classList.remove('is-shadow');
        }, 500);
    }
});
var resultsSlider = new Splide('.splide-results', {
    loop: false,
    perPage: 2,
    perMove: 2,
    pagination: true,
    arrows: true,
    gap: 30,
    padding: 20,
});
if (document.documentElement.offsetWidth < 768) {
    resultsSlider.destroy();
} else {
    resultsSlider.on('drag', function() {
        var cards = document.querySelectorAll('.splide-results .results-card');
        for (var i = 0; i < cards.length; i++) {
            cards[i].classList.add('is-shadow');
        }
    });
    resultsSlider.on('dragged', function() {
        var cards = document.querySelectorAll('.splide-results .results-card');
        for (var i = 0; i < cards.length; i++) {
            removeShadow(cards[i]);
        }
        function removeShadow(elem) {
            setTimeout(function() {
                elem.classList.remove('is-shadow');
            }, 500);
        }
    });
}
var themesSlider = new Splide('.splide-themes', {
    loop: false,
    perPage: 5,
    // perMove: 2,
    pagination: true,
    arrows: true,
    gap: 20,
    padding: 20,
    breakpoints: {
        1023: {
            perPage: 4,
        },
        767: {
            perPage: 3,
        },
        479: {
            perPage: 2,
            fixedWidth: '110px',
            gap: 10
        }
    }
});
themesSlider.on('drag', function() {
    var cards = document.querySelectorAll('.splide-themes .themes-card');
    for (var i = 0; i < cards.length; i++) {
        cards[i].classList.add('is-shadow');
    }
});
themesSlider.on('dragged', function() {
    var cards = document.querySelectorAll('.splide-themes .themes-card');
    for (var i = 0; i < cards.length; i++) {
        removeShadow(cards[i]);
    }
    function removeShadow(elem) {
        setTimeout(function() {
            elem.classList.remove('is-shadow');
        }, 500);
    }
});
var reviewsSlider = new Splide('.splide-reviews', {
    loop: false,
    perPage: 3,
    perMove: 1,
    clones: 0,
    pagination: true,
    arrows: true,
    gap: 22,
    padding: 20,
    breakpoints: {
        1023: {
            perPage: 2,
        },
        650: {
            perPage: 1,
            autoHeight: true
        }
    }
});
reviewsSlider.on('drag', function() {
    var cards = document.querySelectorAll('.splide-reviews .reviews-card');
    for (var i = 0; i < cards.length; i++) {
        cards[i].classList.add('is-shadow');
    }
});
reviewsSlider.on('dragged', function() {
    var cards = document.querySelectorAll('.splide-reviews .reviews-card');
    for (var i = 0; i < cards.length; i++) {
        removeShadow(cards[i]);
    }
    function removeShadow(elem) {
        setTimeout(function() {
            elem.classList.remove('is-shadow');
        }, 500);
    }
});

var newsSlider;
if (document.querySelector('.splide-news')) {
    var newsSlider = new Splide('.splide-news', {
        loop: false,
        perPage: 1,
        perMove: 1,
        pagination: true,
        arrows: true,
        // gap: 22,
        breakpoints: {
            600: {
                perPage: 2,
                // dragAngleThreshold: 1
            },
            450: {
                perPage: 1
            }
        }
    });
    newsSlider.mount();
}
problemsSlider.mount();
resultsSlider.mount();
themesSlider.mount();
reviewsSlider.mount();
/* ======= */






/* VARIABLES */
var header = document.querySelector('#header');
var wrapper = document.querySelector('.wrapper');
var burger = document.querySelector('#burger');
var headerMenu = document.querySelector('.header__bottom');
var headerMenuItems = document.querySelectorAll('.header .menu__link');
var footerMenuItems = document.querySelectorAll('.footer__nav-link');
var forms = document.querySelectorAll('form.request__form');
var newsletterForm = document.querySelector('#newsletterForm');
var problems = {
    cards: document.querySelectorAll('.problems-card'),
    labels: document.querySelectorAll('.problems-card__cb'),
    checkboxes: document.querySelectorAll('.problems-card__cb input'),
    btn: document.querySelector('#problemsBtn'),
    info: document.querySelector('#problemsInfo'),
    output: document.querySelector('#problemsCount'),
    title: document.querySelector('.problems__title'),
    outputString: ' проблем',
    limit: parseInt(document.querySelector('#problems').getAttribute('data-limit')),
    checkedCount: 0,
};
var quiz = {
    questionTitles: document.querySelectorAll('.quiz__question'),
    count: document.querySelector('.quiz__count'),
    contents: document.querySelectorAll('.quiz__answers'),
    answers: document.querySelectorAll('.quiz__answer'),
    radio: document.querySelectorAll('.quiz__answer input'),
    labels: document.querySelectorAll('.quiz__radio-label'),
    btn: document.querySelector('.quiz__btn'),
    request: document.querySelector('.quiz .request'),
    form: document.querySelector('.quiz .request__form'),
    page: 0,
};
var quizData = {
    questionTexts: [],
    answerTexts: [],
    answerValues: [],
    result: 0
};
var request = {
    checkboxes: document.querySelectorAll('.request__cb'),
    labels: document.querySelectorAll('.request__policy-label'),
};
var results = {
    btn: document.querySelector('#moreResults'),
    cards: document.querySelectorAll('.results-card'),
    isHidden: false,
    visibleCount: 2,
    cardTexts: [],
};
var news = {
    items: document.querySelectorAll('.news-grid__item'),
    sliderList: document.querySelector('.news__slider .news__list'),
    type: 'row',
};
var btnsCallPopup = document.querySelectorAll('[data-call-popup]');
var popupElem = document.querySelector('#popup');
var popupSuccess = document.querySelector('#popupSuccess');
var scrollPrev = 0;
var headerHeight = parseInt(header.getBoundingClientRect().height);
var canShowHeader = true;


/* FUNCTIONS */
function setupHeader() {
    headerHeight = parseInt(header.getBoundingClientRect().height);
    wrapper.style.paddingTop = headerHeight + 'px';
}
function showHeader() {
    header.classList.remove('hidden');
    header.classList.add('shown');
}
function hideHeader() {
    header.classList.remove('shown');
    header.classList.add('hidden');
}
function stickHeader() {
    var scrolled = document.documentElement.scrollTop;
    // console.log('Предыдущая прокрутка: ' + scrollPrev);
    // console.log('Текущая прокрутка: ' + scrolled);
    if (scrolled > headerHeight && scrolled > scrollPrev) {
        hideHeader();
    } else if (canShowHeader) {
        showHeader();
    }
    canShowHeader = true;
    scrollPrev = scrolled;
}
function menuHandler() {
    if (burger.classList.contains('active')) {
        hideMenu();
    } else {
        showMenu();
    }
}
function showMenu() {
    burger.classList.add('active');
    headerMenu.classList.add('active');
}
function hideMenu() {
    burger.classList.remove('active');
    headerMenu.classList.remove('active');
}
function scrollHandler(elem) {
    var href = elem.getAttribute('href').substr(1);
    event.preventDefault();
    document.getElementById(href).scrollIntoView({block: "start", behavior: "smooth"});
    burger.classList.remove('active');
    headerMenu.classList.remove('active');
}
function setProblemChecked(ind) {
    problems.checkboxes[ind].checked = !problems.checkboxes[ind].checked;
    problemsHandler(ind);
}
function problemsHandler(ind) {
    if (problems.checkboxes[ind].checked) {
        problems.checkedCount++;
        problems.labels[ind].classList.add(getCheckedProblemsClassName());
    } else {
        problems.checkedCount--;
        problems.labels[ind].classList.remove(getCheckedProblemsClassName());
    }
    showProblemsCount();
}
function setupProblemsHeader() {
    var header = document.querySelector('.problems__header');
    var height = parseInt(header.getBoundingClientRect().height) + 'px';
    header.style.height = height;
}
function setProblemsLabelsIsDone() {
    for (var j = 0; j < problems.labels.length; j++) {
        if (problems.labels[j].classList.contains('active')) {
            problems.labels[j].classList.remove('active');
            problems.labels[j].classList.add('done');
        }
    }
}
function setProblemsLabelsIsAcitve() {
    for (var j = 0; j < problems.labels.length; j++) {
        if (problems.labels[j].classList.contains('done')) {
            problems.labels[j].classList.remove('done');
            problems.labels[j].classList.add('active');
        }
    }
}
function getCheckedProblemsClassName() {
    var className;
    if (problems.checkedCount < problems.limit) {
        setProblemsLabelsIsAcitve();
        className = 'active';
        problemsChangeState(false);
        toggleTitleContent('default');
    } else if (problems.checkedCount >= problems.limit) {
        setProblemsLabelsIsDone();
        className = 'done';
        problemsChangeState(true);
        toggleTitleContent('ready');
    } 
    toggleProblemsOutputClassName();
    return className;
}
function toggleTitleContent(state) {
    if (state === 'default') {
        problems.title.innerHTML = problems.defaultTitleContent;
        problems.title.classList.remove('ready');
    } else if (state === 'ready') {
        problems.title.innerHTML = problems.readyTitleContent;
        problems.title.classList.add('ready');
    }
}
function showProblemsCount() {
    switch (problems.checkedCount) {
        case 1:
            problems.outputString = ' проблема.';
            break;
        case 2:
        case 3:
        case 4:
            problems.outputString = ' проблемы.';
            break;
        default:
            problems.outputString = ' проблем.';
            break;
    }
    problems.output.textContent = problems.checkedCount + problems.outputString;
}
function toggleProblemsOutputClassName() {
    if (problems.checkedCount > 0) {
        problems.output.parentElement.classList.add('not-zero');
    } else {
        problems.output.parentElement.classList.remove('not-zero');
    }
}
function problemsChangeState(isReady) {
    if (isReady) {
        problems.info.classList.add('hidden');
        problems.btn.classList.remove('hidden');
        problems.title.classList.add('ready');

        /* это костыль, который используется единожды, чтобы хедер не прыгал */
        canShowHeader = false;
        document.getElementById('problems').scrollIntoView({block: 'start'});

    } else {
        problems.btn.classList.add('hidden');
        problems.info.classList.remove('hidden');
        problems.title.classList.remove('ready');
    }
}
function radioHandler(itemList, radioList) {
    for (var i = 0; i < itemList.length; i++) {
        var select = selectRadio.bind(null, i);
        var handler = selectItem.bind(null, i);
        itemList[i].addEventListener('click', select);
        itemList[i].addEventListener('change', handler);
    }
    function selectItem(ind) {
        for (var j = 0; j < itemList.length; j++) {
            itemList[j].classList.remove('checked');
        }
        itemList[ind].classList.add('checked');
    }
    function selectRadio(ind) {
        radioList[ind].checked = true;
        selectItem(ind);
    }
}
function requestCbHandler(cb, label) {
    if (cb.checked) {
        label.classList.add('checked');
    } else {
        label.classList.remove('checked');
    }
}
function showOrHideMoreResults(visibleCount = 0) {
    if (results.isHidden) {
        for (var i = 0; i < results.cards.length; i++) {
            results.cards[i].classList.remove('hidden');
            results.btn.textContent = 'скрыть';
        }
    } else {
        for (var i = visibleCount; i < results.cards.length; i++) {
            results.cards[i].classList.add('hidden');
            results.btn.textContent = 'показать еще';
        }
    }
    results.isHidden = !results.isHidden;
}
function expandCard(ind) {
    var textElem = results.cards[ind].querySelector('.results-card__text');
    var text = results.cardTexts[ind];
    insertTextInCard(text, textElem);
    results.cards[ind].classList.remove('closed');
    results.cards[ind].classList.add('opened');
}
function rollUpCard(ind) {
    var textElem = results.cards[ind].querySelector('.results-card__text');
    var length = textElem.getAttribute('data-cutted-length');
    var cuttedText = results.cardTexts[ind].split(' ').slice(0, length - 2).join(' ') + ' ...';
    insertTextInCard(cuttedText, textElem);
    results.cards[ind].classList.remove('opened');
    results.cards[ind].classList.add('closed');
}
function insertTextInCard(text, textElem) {
    var span = document.createElement('span');
    span.textContent = text;
    textElem.innerHTML = '';
    textElem.appendChild(span);
}
function setupCardContent(ind) {
    var textElem = results.cards[ind].querySelector('.results-card__text');
    var result = checkStringLength(5, ind, textElem);
    if (result.isMore) {
        textElem.setAttribute('data-cutted-length', result.length);
        results.cards[ind].classList.add('closed');
    }
}
function checkStringLength(num, ind, textElem) {
    var text = results.cardTexts[ind];
    var textArr = text.split(' ');
    var resultText = '';

    var span = document.createElement('span');
    for (var k = 0; k < textArr.length; k++) {
        resultText += textArr[k] + ' ';
        textElem.innerHTML = '';
        span.textContent = resultText;
        textElem.appendChild(span);
        if (span.getClientRects().length >= num) {
            span.textContent = text;
            textElem.innerHTML = '';
            textElem.appendChild(span);
            return {
                isMore: true,
                length: k
            };
        }
    }
    return {
        isMore: false,
        length: k
    };
}
function expandAllCards() {
    for (var i = 0; i < results.cards.length; i++) {expandCard(i);}
}
function rollUpAllCards() {
    for (var i = 0; i < results.cards.length; i++) {rollUpCard(i);}
}
function cardContentHandler(ind) {
    if (results.cards[ind].classList.contains('closed')) {
        expandCard(ind);
    } else if (results.cards[ind].classList.contains('opened')) {
        rollUpCard(ind);
    }
}
function rebuildsResultCards() {
    if (document.documentElement.offsetWidth < 768) {
        for (var i = 0; i < results.cards.length; i++) {
            setupCardContent(i);
            if (results.cards[i].querySelector('.results-card__text').hasAttribute('data-cutted-length')) {
                var cardHandler = cardContentHandler.bind(null, i);
                results.cards[i].addEventListener('click', cardHandler);
                rollUpCard(i);
            }
        }
        results.btn.addEventListener('click', showOrHideMoreResults.bind(null, results.visibleCount));
        showOrHideMoreResults(2);
    }
}
function popupHandler(elem) {
    if (elem.classList.contains('hidden')) {
        elem.classList.remove('hidden');
        document.body.classList.add('lock');
    } else {
        elem.classList.add('hidden');
        document.body.classList.remove('lock');
    }
}
function popupOverlayHandler(elem) {
    if (event.target.classList.contains('popup') || event.target.classList.contains('popup__close')) {
        popupHandler(elem);
    }
}
// function selectCheckbox(ind) {
//     if (event.target !== problems.labels[ind]) {
//         problems.checkboxes[ind].click();
//     }
// }
function setupQuiz() {
    quiz.total = quiz.contents.length + 1;
    quiz.request.classList.remove('active');
    for (var i = 0; i < quiz.questionTitles.length; i++) {
        quiz.questionTitles[i].textContent = (i + 1) + '. ' + quiz.questionTitles[i].textContent;
    }
    showCurrentQuizPage();
}
function showCurrentQuizPage() {
    hideAllQuizPages();
    quiz.questionTitles[quiz.page].classList.add('active');
    quiz.contents[quiz.page].classList.add('active');
    quiz.count.textContent = (quiz.page+1) + '/' + quiz.total;
}
function hideAllQuizPages() {
    for (var i = 0; i < quiz.contents.length; i++) {
        quiz.questionTitles[i].classList.remove('active');
        quiz.contents[i].classList.remove('active');
    }
}
function isAllowIncreaseQuiz() {
    var radio = document.querySelectorAll('.quiz__answers.active input[type="radio"]');
    for (var i = 0; i < radio.length; i++) {
        if (radio[i].checked) {
            return true;
        }
    }
    return false;
}
function addQuizData() {
    var inputs = quiz.contents[quiz.page].querySelectorAll('input[type="radio"]');
    var answers = quiz.contents[quiz.page].querySelectorAll('.quiz__answer span');
    var questionText = quiz.questionTitles[quiz.page].textContent;
    var answerValue;
    var answerText;
    for (var i = 0; i < inputs.length; i++) {
        if (inputs[i].checked) {
            answerValue = inputs[i].value;
            answerText = answers[i].textContent;
            break;
        }
    }
    quizData.questionTexts.push(questionText);
    quizData.answerTexts.push(answerText);
    quizData.answerValues.push(answerValue);

}
function showQuizFinal() {
    quiz.count.textContent = quiz.total + '/' + quiz.total;
    quiz.btn.classList.add('hidden');
    quiz.request.classList.add('active');
}
function formationQuizInputs() {
    var inputs = [];
    // var result = 0;
    function addInputsToArr(q, a, num) {
        var question = document.createElement('input');
        var answer = document.createElement('input');
        question.setAttribute('type', 'hidden');
        question.setAttribute('name', 'question' + num);
        question.setAttribute('value', q);
        answer.setAttribute('type', 'hidden');
        answer.setAttribute('name', 'answer' + num);
        answer.setAttribute('value', a);
        inputs.push(question, answer);
    }
    for (var i = 0; i < quizData.answerValues.length; i++) {
        addInputsToArr(quizData.questionTexts[i], quizData.answerTexts[i], i + 1);
        quizData.result += parseInt(quizData.answerValues[i]);
    }
    for (var i = 0; i < inputs.length; i++) {
        quiz.form.appendChild(inputs[i]);
    }
    var inputResult = document.createElement('input');
    inputResult.setAttribute('type', 'hidden');
    inputResult.setAttribute('name', 'result');
    inputResult.setAttribute('value', quizData.result);
    quiz.form.appendChild(inputResult);
}
function increaseQuizPage() {
    if (quiz.page < quiz.total-2 && isAllowIncreaseQuiz()) {
        addQuizData();
        quiz.page++;
        showCurrentQuizPage();
    } else if (quiz.page === quiz.total-2 && isAllowIncreaseQuiz()) {
        addQuizData();
        hideAllQuizPages();
        showQuizFinal();
        formationQuizInputs();
    } else {
        alert('Выберите один из вариантов ответа');
    }
}
function setupNewsSlider() {
    function getElemHtml(elem) {
        if (elem) {return elem.outerHTML;} 
        else {return '';}
    }

    if (news.type === 'row' && document.documentElement.offsetWidth > 600) {
        // news.slides = document.querySelectorAll('.news__slide');
        // newsSlider.remove(0);
        for (var i = newsSlider.length - 1; i >= 0; i--) {
            newsSlider.remove(i);
        }
        for (var i = 0; i < news.items.length; i+=4) {
            var itemHtml1 = getElemHtml(news.items[i]);
            var itemHtml2 = getElemHtml(news.items[i+1]);
            var itemHtml3 = getElemHtml(news.items[i+2]);
            var itemHtml4 = getElemHtml(news.items[i+3]);
            var html = '<li class="splide__slide news-grid">' + 
                itemHtml1 + 
                itemHtml2 + 
                itemHtml3 + 
                itemHtml4 + 
                '</li>';
            newsSlider.add(html, -1);
        }
        news.type = 'grid';
    } else if (news.type === 'grid' && document.documentElement.offsetWidth <= 600) {
        for (var i = newsSlider.length - 1; i >= 0; i--) {
            newsSlider.remove(i);
        }
        for (var i = 0; i < news.items.length; i++) {
            var elem = news.items[i];
            var html = '<li class="splide__slide news__slide">' + elem.outerHTML + '</li>';
            newsSlider.add(html, -1);
        }
        news.type = 'row';
    }
}
function serialize(form) {
	if (!form || form.nodeName !== "FORM") {
		return false;
	}
	var i, j, q = [];
	for (i = form.elements.length - 1; i >= 0; i = i - 1) {
		if (form.elements[i].name === "") {
			continue;
		}
		switch (form.elements[i].nodeName) {
			case 'INPUT':
				switch (form.elements[i].type) {
					case 'text':
					case 'tel':
					case 'email':
					case 'hidden':
					case 'password':
					case 'button':
					case 'reset':
					case 'submit':
						q.push(form.elements[i].name + "=" + encodeURIComponent(form.elements[i].value));
						break;
					case 'checkbox':
					case 'radio':
						if (form.elements[i].checked) {
							q.push(form.elements[i].name + "=" + encodeURIComponent(form.elements[i].value));
						}
						break;
				}
				break;
			case 'file':
				break;
			case 'TEXTAREA':
				q.push(form.elements[i].name + "=" + encodeURIComponent(form.elements[i].value));
				break;
			case 'SELECT':
				switch (form.elements[i].type) {
					case 'select-one':
						q.push(form.elements[i].name + "=" + encodeURIComponent(form.elements[i].value));
						break;
					case 'select-multiple':
						for (j = form.elements[i].options.length - 1; j >= 0; j = j - 1) {
							if (form.elements[i].options[j].selected) {
								q.push(form.elements[i].name + "=" + encodeURIComponent(form.elements[i].options[j].value));
							}
						}
						break;
				}
				break;
			case 'BUTTON':
				switch (form.elements[i].type) {
					case 'reset':
					case 'submit':
					case 'button':
						q.push(form.elements[i].name + "=" + encodeURIComponent(form.elements[i].value));
						break;
				}
				break;
		}
    }
    return q.reverse().join("&");
}
function sendForm(form) {
    event.preventDefault();
    if (validateForm(form)) {
        var xhr = new XMLHttpRequest();
        var body = serialize(form);
        xhr.open('POST', './mail.php');
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.onreadystatechange = function() {
            if (xhr.readyState == 4 && xhr.status == 200) {
                form.reset();
                popupHandler(popupSuccess);
            } else if (xhr.readyState == 4 && xhr.status !== 200) {
                alert('Что-то пошло не так... Повторите отправку формы');
            }
        };
        xhr.send(body);
    } else {
        alert('Некоторые данные введены некорректно и/или вы не согласились с условиями обработки персональных данных');
    }
}
function validateForm(form) {
    function policyIsValid(form) {
        var policy = form.querySelector('input[type="checkbox"][name="policy"]');
        if (!policy || policy.checked) {
            return true;
        } else {return false;}
    }
    function telIsValid(form) {
        var regTel = /(\+)?[\d|\s]{1,20}$/;
        var tel = form.querySelector('input[type="tel"][name="tel"]');
        if (!tel || tel.value.match(regTel)) {
            return true;
        } else {
            return false;
        }
    }
    function nameIsValid() {
        return true;
    }
    function emailIsValid(form) {
        var regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var email = form.querySelector('input[type="email"][name="email"]');
        if (!email || email.value.match(regEmail)) {
            return true;
        } else {
            return false;
        }
    }
    return (policyIsValid(form) && telIsValid(form) && nameIsValid() && emailIsValid(form));
}
/* ========= */



/* HANDLERS */
setupHeader();
radioHandler(quiz.answers, quiz.radio);
setupQuiz();

setupProblemsHeader();



quiz.btn.addEventListener('click', increaseQuizPage);
window.addEventListener('scroll', stickHeader);
window.addEventListener('resize', setupHeader);
burger.addEventListener('click', menuHandler);
window.addEventListener('resize', setupProblemsHeader);
newsletterForm.addEventListener('submit', sendForm.bind(null, newsletterForm));

for (var i = 0; i < forms.length; i++) {
    var f = forms[i];
    f.addEventListener('submit', sendForm.bind(null, f));
    f = null;
}

for (var i = 0; i < headerMenuItems.length; i++) {
    var elem = headerMenuItems[i];
    elem.addEventListener('click', scrollHandler.bind(null, elem));
}
for (var i = 0; i < footerMenuItems.length; i++) {
    var elem = footerMenuItems[i];
    elem.addEventListener('click', scrollHandler.bind(null, elem));
}

problems.defaultTitleContent = problems.title.innerHTML;
problems.readyTitleContent = problems.title.getAttribute('data-ready-text');

// for (var i = 0; i < problems.cards.length; i++) {
//     var handler = problemsHandler.bind(null, i);
//     problems.checkboxes[i].addEventListener('change', handler);
    // console.log(true);
    // var selectCb = selectCheckbox.bind(null, i);
    // problems.cards[i].addEventListener('click', selectCb);
// }

/* QUIZ */


for (var i = 0; i < request.checkboxes.length; i++) {
    var handler = requestCbHandler.bind(null, request.checkboxes[i], request.labels[i]);
    request.checkboxes[i].addEventListener('click', handler);
}

for (var i = 0; i < results.cards.length; i++) {
    var textElem = results.cards[i].querySelector('.results-card__text');
    results.cardTexts.push(textElem.textContent);
}


rebuildsResultCards();
window.addEventListener('resize', rebuildsResultCards);


/* NEWS */
if (document.querySelector('.splide-news')) {
    setupNewsSlider();
    window.addEventListener('resize', setupNewsSlider);
}

popupElem.addEventListener('mousedown', popupOverlayHandler.bind(null, popupElem));
popupSuccess.addEventListener('mousedown', popupOverlayHandler.bind(null, popupSuccess));
for (var i = 0; i < btnsCallPopup.length; i++) {
    var elem = document.querySelector(btnsCallPopup[i].getAttribute('data-call-popup'));
    var handler = popupHandler.bind(null, elem);
    btnsCallPopup[i].addEventListener('click', handler);
}

for (var i = 0; i < problems.cards.length; i++) {
    var elem = problems.cards[i];
    elem.addEventListener('click', setProblemChecked.bind(null, i));
}

// })();